import { useContext, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import AuthContext from '../../AuthContext';

const PrivateRoute = () => {
  const { token, logout } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const now = Date.now() / 1000;

        if (decoded.exp && decoded.exp < now) {
          console.warn("Token expired. Logging out.");
          logout();
        }
      } catch (err) {
        console.error("Invalid token:", err);
        logout();
      }
    }
  }, [token, logout]);

  return token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
